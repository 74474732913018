<h1 mat-dialog-title>{{ 'id' | translate }}: {{data.site.id}}</h1>
<div mat-dialog-content>
  <p>{{ 'SITE_INVESTIGATION/dialog/approve' | translate }}</p>
  <mat-checkbox [(ngModel)]="approveWithoutCost"> Aprovar Laudo com custo zerado no Finance</mat-checkbox>
  <!-- <ng-contaneiner *ngIf="approveWithoutCost === true">
    <p>Ao confirmar a ação, o custo será enviado para o Finance com valor zerado</p>
  </ng-contaneiner> -->
</div>
<div mat-dialog-actions>
  <button mat-button class="primary-button" (click)="onClickOk()" cdkFocusInitial>{{ 'SITE_INVESTIGATION/dialog/btn/ok' | translate }}</button>
</div>
