<div class="column has-text-centered row">
  <div class="col-sm" *ngFor="let image of _albums; let i=index">
    <ng-container [ngSwitch]="imageExtensions.indexOf(image.thumb.split('.').pop().toLowerCase())">
      <i *ngSwitchCase='-1' class="img-frame fas fa-file" (click)="open_tab(i)"></i>
      <img *ngSwitchDefault class="img-frame " [src]="image.thumb" (click)="open(i)" />
      <div *ngIf="showDate && image.createdAt" [style.text-align]="'center'">
        Data de Upload: {{ image.createdAt | date :'d/M/yy, h:mm a'}}
      </div>
    </ng-container>
  </div>
</div>
