export class Driver {
  id: number;
  name: number;
  plate: string;
  phoneNumber: number;
  mdrId: number;
  status: boolean;
  leader: boolean;

  constructor() {
    this.id = null;
    this.name = null;
    this.plate = '';
    this.phoneNumber = null;
    this.mdrId = null;
    this.status = true;
    this.leader = false;
  }
}
