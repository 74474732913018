import { Component, OnInit, Input } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';
import {environment} from '../../../environments/environment';
@Component({
  selector: 'app-image-lightbox',
  templateUrl: './image-lightbox.component.html',
  styleUrls: ['./image-lightbox.component.scss']
})
export class ImageLightboxComponent implements OnInit {
  @Input() images: any ;
  @Input() showDate = false;
  public _albums: any = [];
  public imageExtensions = ['png', 'jpeg', 'jpg', 'gif'];

  constructor(private _lightbox: Lightbox) {

  }

  open(index: number): void {
    // override the default config
    this._lightbox.open(this._albums, index);
  }

  open_tab(index: number): void {
    window.open(this._albums[index].src, '_blank');
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  ngOnInit() {
    for (let i = 0; i < this.images.length; i++) {
      const urlKey = ('url' in this.images[i]) ? 'url' : 'path';
      const src = this.images[i][urlKey];
      const id = this.images[i].id;
      const thumb = this.images[i][urlKey];
      const createdAt = this.images[i].createdAt ? this.images[i].createdAt : null;
      const album = {
        src: src,
        thumb: thumb,
        createdAt: createdAt,
      };
      this._albums.push(album);
    }
  }




}
