import { OnInit, OnDestroy, ElementRef } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';
import { DrService } from '../../providers/dr.service';
import { MDR } from '../../models/mdr';
import { MdrService } from '../../providers/mdr.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MapsAPILoader } from '@agm/core';
import { HubService } from '../../providers/hub.service';
import { PriceMakerService } from '../../providers/price-maker.service';
var DeliveryOptimizerComponent = /** @class */ (function () {
    function DeliveryOptimizerComponent(dialog, mapsAPILoader, drService, mdrService, router, snackBar, dpipe) {
        this.dialog = dialog;
        this.mapsAPILoader = mapsAPILoader;
        this.drService = drService;
        this.mdrService = mdrService;
        this.router = router;
        this.snackBar = snackBar;
        this.dpipe = dpipe;
        this.data = [];
        this.columns = [
            { value: 'idDRidentifier', name: 'id' },
            { value: 'deliveryAddress', name: 'DR_OPTIMIZER/address' },
            { value: 'dateScheduling', name: 'DR_OPTIMIZER/date' },
            { value: 'site', name: 'DR_OPTIMIZER/site' },
            { value: 'createdBy', name: 'DR_OPTIMIZER/created' },
            { value: 'client', name: 'DR_OPTIMIZER/table/client' },
            { value: 'ovList', name: 'DR_OPTIMIZER/table/ovList' },
            { value: 'uf', name: 'DR_OPTIMIZER/table/uf' }
        ];
        this.selected = [];
        this.subscriptions = [];
    }
    DeliveryOptimizerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.drService.getAllOpen().subscribe(function (data2) {
            data2.forEach(function (row) {
                if (row.ovList) {
                    row.ovList = row.ovList;
                }
                else {
                    row.ovList = '';
                }
            });
            _this.data = data2;
        }));
    };
    DeliveryOptimizerComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    DeliveryOptimizerComponent.prototype.unmakeDR = function () {
        var _this = this;
        this.subscriptions.push(this.drService.unmakeDR(this.selected).subscribe(function (data) {
            _this.subscriptions.push(_this.drService.getAllOpen().subscribe(function (data2) {
                _this.data = data2;
                _this.snackBar.open('Ordens de entrega desfeitas com sucesso', 'Ok', {
                    duration: 4000,
                });
            }));
        }));
    };
    DeliveryOptimizerComponent.prototype.createConsolidationMDR = function () {
        var _this = this;
        var mdr = new MDR();
        mdr.status = 'MDR em validação';
        this.selected.forEach(function (el) {
            mdr.drList.push(el);
        });
        this.subscriptions.push(this.mdrService.insertConsolidation(mdr).subscribe(function (data) {
            // console.log('MDR new Service');
            // console.log(data);
            _this.subscriptions.push(_this.mdrService.createLegs(data.id, false).subscribe(function (legs) {
                // console.log(legs);
            }));
            _this.subscriptions.push(_this.drService.getAllOpen().subscribe(function (data2) {
                _this.data = data2;
                _this.snackBar.open('MDR EDB-TP-000' + data.id + ' criada com sucesso', 'Ok', {
                    duration: 4000,
                });
                _this.router.navigate(['logistic-order', data.id]);
            }));
        }));
    };
    DeliveryOptimizerComponent.prototype.createNewConsolidationMDR = function (mdr) {
        var _this = this;
        mdr.status = 'Nova MDR';
        this.subscriptions.push(this.mdrService.insertNewConsolidation(mdr).subscribe(function (data) {
            // console.log('MDR new Service');
            // console.log(data);
            _this.subscriptions.push(_this.mdrService.createLegs(data.id, false).subscribe(function (legs) {
                // console.log(legs);
            }));
            _this.subscriptions.push(_this.drService.getAllOpen().subscribe(function (data2) {
                _this.data = data2;
                _this.snackBar.open('MDR EDB-TP-000' + data.id + ' criada com sucesso', 'Ok', {
                    duration: 4000,
                });
                _this.router.navigate(['logistic-order', data.id]);
            }));
        }));
    };
    DeliveryOptimizerComponent.prototype.openDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(TypeMdrDialogComponent, {
            width: '800px',
            height: '700px',
            data: {
                id: 1,
                selected: this.selected
            }
        });
        this.subscriptions.push(dialogRef.afterClosed().subscribe(function (data) {
            if (data !== undefined) {
                _this.createNewMDR(data);
            }
        }));
    };
    DeliveryOptimizerComponent.prototype.createNewMDR = function (typeMDR) {
        var _this = this;
        // console.log(typeMDR);
        // cria MDR
        var mdr = new MDR();
        mdr.type = typeMDR.type;
        mdr.status = 'MDR em validação';
        mdr.fiscalInState = typeMDR.fiscalInState;
        mdr.fiscalOutState = typeMDR.fiscalOutState;
        // adiciona DRs selecionadas
        this.selected.forEach(function (el) {
            mdr.drList.push(el);
        });
        var flag = false;
        if (mdr.type === 'Cross Doc') {
            this.createConsolidationMDR();
            return;
        }
        else if (mdr.type === 'Cross Docking' && typeMDR.data) {
            this.createNewConsolidationMDR(mdr);
            return;
        }
        else if (mdr.type === 'CMS' && typeMDR.data) {
            mdr.SAD = typeMDR.data;
            if (typeMDR.data === true) {
                flag = true;
            }
        }
        else if (mdr.type === 'DTR' && typeMDR.data) {
            mdr['dtr'] = typeMDR.data;
            mdr['drInfo'] = {
                pickupDate: typeMDR.pickupDate,
                pickupPhone: typeMDR.pickupPhone,
                pickupSite: typeMDR.pickupSite,
            };
        }
        else if (mdr.type === 'MF') {
            mdr.status = 'Nova MDR';
        }
        // }else if(mdr.type === 'DTR' && typeMDR.data){
        //   const drList = [];
        //   for(let i = typeMDR.data.length-1; i>=0; i--){
        //     const dr = new DR();
        //     dr.deliveryAddress = typeMDR.data[i].address;
        //     dr.lat = typeMDR.data[i].lat;
        //     dr.lng = typeMDR.data[i].lng;
        //     dr.uf = typeMDR.data[i].uf;
        //     earlydate.setDate(earlydate.getDate() - i - 1);
        //     dr.dateScheduling = earlydate;
        //     drList.push(dr);
        //   }
        //   //console.log(drList);
        // }
        this.subscriptions.push(this.mdrService.insert(mdr, flag).subscribe(function (data) {
            _this.drService.getAllOpen().subscribe(function (data2) {
                _this.data = data2;
                _this.snackBar.open('MDR EDB-TP-000' + data.id + ' criada com sucesso', 'Ok', {
                    duration: 4000,
                });
                _this.router.navigate(['logistic-order', data.id]);
            });
        }));
    };
    DeliveryOptimizerComponent.prototype.onSelection = function (selected) {
        this.selected = selected;
    };
    return DeliveryOptimizerComponent;
}());
export { DeliveryOptimizerComponent };
var TypeMdrDialogComponent = /** @class */ (function () {
    function TypeMdrDialogComponent(dialogRef, mapsAPILoader, data, hubService, snackBar, priceMaker) {
        this.dialogRef = dialogRef;
        this.mapsAPILoader = mapsAPILoader;
        this.data = data;
        this.hubService = hubService;
        this.snackBar = snackBar;
        this.priceMaker = priceMaker;
        this.columns = [
            { value: 'city', name: 'DELIVERY_ORDER/table/observation' },
            { value: 'uf', name: 'status' },
            { value: 'site', name: 'DELIVERY_ORDER/table/client' },
            { value: 'idDRidentifier', name: 'DELIVERY_ORDER/table/shippingCompany' },
            { value: 'hubSelected', name: 'DELIVERY_ORDER/table/hub' },
        ];
        this.types = [{ id: 'Cross Docking', name: 'Cross Docking' }, { id: 'MF', name: 'MF' }, { id: 'EDB', name: 'EDB' }, { id: 'ED', name: 'ED' }, { id: 'CMS', name: 'CMS' }, { id: 'DTR', name: 'DTR' }, { id: 'Cross Doc', name: 'Cross Doc' }];
        this.returnLeg = false;
        this.listAddresses = [];
        this.addressReturn = [];
        this.pickupSite = null;
        this.pickupDate = null;
        this.cellphone = '';
        this.address = null;
        this.hub = null;
        this.hubList = [];
        this.drs = [];
        this.valid = true;
        // fiscal in/out state
        this.icmsState = {
            in: {
                current: null,
                all: [],
            },
            out: {
                current: null,
                all: [],
            }
        };
        this.selectedDrs = [];
        this.subscriptions = [];
    }
    TypeMdrDialogComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    TypeMdrDialogComponent.prototype.goTo = function (row, that) {
        console.log(row);
        // that.router.navigate(['logistic-order', row.id]);
    };
    TypeMdrDialogComponent.prototype.addList = function (listPayments) {
        this.selectedDrs = listPayments;
    };
    TypeMdrDialogComponent.prototype.addHub = function () {
        var _this = this;
        this.selectedDrs.forEach(function (el) {
            _this.drs.forEach(function (k) {
                if (k === el) {
                    el['hubSelected'] = _this.hub;
                    k['hubSelected'] = _this.hub;
                }
            });
        });
        console.log(this.drs);
    };
    TypeMdrDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.typeMDR = null;
        console.log(this.data.selected);
        this.drs = this.data.selected;
        // pega in/out states
        this.subscriptions.push(this.priceMaker.getIcmsStates().subscribe(function (response) {
            _this.icmsState.in.all = response.in;
            _this.icmsState.out.all = response.out;
        }));
        this.mapsAPILoader.load().then(function () {
            var autocomplete = new google.maps.places.Autocomplete(_this.searchElementRef.nativeElement, {
                types: ['address']
            });
            var autocompletepickup = new google.maps.places.Autocomplete(_this.pickupSiteElementRef.nativeElement, {
                types: ['address']
            });
            autocompletepickup.addListener('place_changed', function () {
                var place = autocompletepickup.getPlace();
                _this.pickupSite = place.formatted_address;
            });
        });
        this.subscriptions.push(this.hubService.getAll(this.valid).subscribe(function (resp) {
            _this.hubList = resp;
        }));
    };
    TypeMdrDialogComponent.prototype.addAddress = function () {
        var _this = this;
        var address = (document.getElementById('deliveryAddress').value);
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': address }, function (results, status) {
            // console.log(results);
            var index = _this.listAddresses.length - 1;
            if (status === google.maps.GeocoderStatus.OK) {
                var lat = results[0].geometry.location.lat();
                var lng = results[0].geometry.location.lng();
                var uf = results[0].address_components[2].short_name;
                _this.addressReturn.push({ address: address, lat: lat, lng: lng, uf: uf });
            }
            else {
                _this.addressReturn.splice(index, 1);
                // this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', {duration: 2000});
                // console.log(status);
            }
        });
    };
    TypeMdrDialogComponent.prototype.removeAddress = function (i) {
        this.addressReturn.splice(i, 1);
    };
    TypeMdrDialogComponent.prototype.onYesClick = function () {
        if (this.typeMDR === 'CMS') {
            this.dialogRef.close({
                type: this.typeMDR,
                data: this.returnLeg,
                fiscalInState: this.icmsState.in.current,
                fiscalOutState: this.icmsState.out.current,
            });
        }
        else if (this.typeMDR === 'DTR') {
            this.dialogRef.close({
                type: this.typeMDR,
                data: this.addressReturn,
                fiscalInState: this.icmsState.in.current,
                fiscalOutState: this.icmsState.out.current,
                pickupDate: this.pickupDate,
                pickupSite: this.pickupSite,
                pickupPhone: this.cellphone
            });
        }
        else if (this.typeMDR === 'Cross Docking') {
            // for (let dr of this.drs){
            //   if(dr.hubSelected === null){
            //     this.snackBar.open("Falta selecionar HUBs para entregas", 'OK', {duration: 4000});
            //     return;
            //   }
            // }
            this.dialogRef.close({
                type: this.typeMDR,
                data: this.hub,
                fiscalInState: this.icmsState.in.current,
                fiscalOutState: this.icmsState.out.current,
            });
        }
        else {
            this.dialogRef.close({
                type: this.typeMDR,
                fiscalInState: this.icmsState.in.current,
                fiscalOutState: this.icmsState.out.current,
            });
        }
    };
    TypeMdrDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    return TypeMdrDialogComponent;
}());
export { TypeMdrDialogComponent };
