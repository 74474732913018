/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-lightbox.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./image-lightbox.component";
import * as i4 from "ngx-lightbox/lightbox.service";
var styles_ImageLightboxComponent = [i0.styles];
var RenderType_ImageLightboxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageLightboxComponent, data: {} });
export { RenderType_ImageLightboxComponent as RenderType_ImageLightboxComponent };
function View_ImageLightboxComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "img-frame fas fa-file"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.open_tab(_v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_ImageLightboxComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "img-frame "]], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.open(_v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.thumb; _ck(_v, 0, 0, currVal_0); }); }
function View_ImageLightboxComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], [[4, "text-align", null]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" Data de Upload: ", " "])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var currVal_0 = "center"; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.createdAt, "d/M/yy, h:mm a")); _ck(_v, 1, 0, currVal_1); }); }
function View_ImageLightboxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "col-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, null, null, null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i2.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageLightboxComponent_2)), i1.ɵdid(4, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageLightboxComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageLightboxComponent_4)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageExtensions.indexOf(_v.context.$implicit.thumb.split(".").pop().toLowerCase()); _ck(_v, 2, 0, currVal_0); var currVal_1 = (0 - 1); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.showDate && _v.context.$implicit.createdAt); _ck(_v, 8, 0, currVal_2); }, null); }
export function View_ImageLightboxComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "column has-text-centered row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageLightboxComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._albums; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ImageLightboxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-image-lightbox", [], null, null, null, View_ImageLightboxComponent_0, RenderType_ImageLightboxComponent)), i1.ɵdid(1, 114688, null, 0, i3.ImageLightboxComponent, [i4.Lightbox], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageLightboxComponentNgFactory = i1.ɵccf("app-image-lightbox", i3.ImageLightboxComponent, View_ImageLightboxComponent_Host_0, { images: "images", showDate: "showDate" }, {}, []);
export { ImageLightboxComponentNgFactory as ImageLightboxComponentNgFactory };
