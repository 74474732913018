import { Component, OnInit, OnDestroy, ViewChild, Inject, ElementRef } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DrService } from '../../providers/dr.service';
import { DR } from '../../models/dr';
import { MDR } from '../../models/mdr';
import { MdrService } from '../../providers/mdr.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MapsAPILoader } from '@agm/core';
import { HubService } from '../../providers/hub.service';
import { PriceMakerService } from '../../providers/price-maker.service';

declare var google: any;

@Component({
  selector: 'app-delivery-optimizer',
  templateUrl: './delivery-optimizer.component.html',
  styleUrls: ['./delivery-optimizer.component.scss']
})

export class DeliveryOptimizerComponent implements OnInit, OnDestroy {
  data: DR[] = [];
  columns = [
    {value: 'idDRidentifier', name: 'id'},
    {value: 'deliveryAddress', name: 'DR_OPTIMIZER/address'},
    {value: 'dateScheduling', name: 'DR_OPTIMIZER/date'},
    {value: 'site', name: 'DR_OPTIMIZER/site'},
    {value: 'createdBy', name: 'DR_OPTIMIZER/created'},
    {value: 'client', name: 'DR_OPTIMIZER/table/client'},
    {value: 'ovList', name: 'DR_OPTIMIZER/table/ovList'},
    {value: 'uf', name: 'DR_OPTIMIZER/table/uf'}
  ];
  selected: DR[] = [];

  @ViewChild('search')
  public searchElementRef: ElementRef;

  subscriptions: any[] = [];

  constructor(public dialog: MatDialog,
              private mapsAPILoader: MapsAPILoader,
              private drService: DrService,
              public mdrService: MdrService,
              public router: Router,
              public snackBar: MatSnackBar,
              public dpipe: DatePipe) { }

  ngOnInit() {
    this.subscriptions.push(this.drService.getAllOpen().subscribe( (data2) => {
      data2.forEach( (row) => {
        if (row.ovList) {
          row.ovList = row.ovList;
        } else {
          row.ovList = '';
        }
      });
      this.data = data2;
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  unmakeDR() {
      this.subscriptions.push(this.drService.unmakeDR(this.selected).subscribe(
        (data) => {
          this.subscriptions.push(this.drService.getAllOpen().subscribe(
            (data2) => {
              this.data = data2;
              this.snackBar.open('Ordens de entrega desfeitas com sucesso', 'Ok', {
                duration: 4000,
              });
            }
          ));
        }));
  }

  createConsolidationMDR() {
    const mdr = new MDR();
    mdr.status = 'MDR em validação';
    this.selected.forEach((el: DR) => {
      mdr.drList.push(el);
    });
    this.subscriptions.push(this.mdrService.insertConsolidation(mdr).subscribe( (data) => {
     // console.log('MDR new Service');
     // console.log(data);
      this.subscriptions.push(this.mdrService.createLegs(data.id, false).subscribe((legs) => {
       // console.log(legs);
      }));
      this.subscriptions.push(this.drService.getAllOpen().subscribe(
        (data2) => {
          this.data = data2;
          this.snackBar.open('MDR EDB-TP-000' + data.id + ' criada com sucesso', 'Ok', {
            duration: 4000,
          });
          this.router.navigate(['logistic-order', data.id]);
        }
      ));
    }));
  }

  createNewConsolidationMDR(mdr) {
    mdr.status = 'Nova MDR';
    this.subscriptions.push(this.mdrService.insertNewConsolidation(mdr).subscribe( (data) => {
     // console.log('MDR new Service');
     // console.log(data);
      this.subscriptions.push(this.mdrService.createLegs(data.id, false).subscribe((legs) => {
       // console.log(legs);
      }));
      this.subscriptions.push(this.drService.getAllOpen().subscribe(
        (data2) => {
          this.data = data2;
          this.snackBar.open('MDR EDB-TP-000' + data.id + ' criada com sucesso', 'Ok', {
            duration: 4000,
          });
          this.router.navigate(['logistic-order', data.id]);
        }
      ));
    }));
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(TypeMdrDialogComponent, {
      width: '800px',
      height: '700px',
      data: {
        id: 1,
        selected: this.selected
      }
    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe(data => {
      if (data !== undefined) {
        this.createNewMDR(data);
      }
    }));
  }

  createNewMDR(typeMDR: any) {
    // console.log(typeMDR);
    // cria MDR
    const mdr = new MDR();
    mdr.type = typeMDR.type;
    mdr.status = 'MDR em validação';
    mdr.fiscalInState = typeMDR.fiscalInState;
    mdr.fiscalOutState = typeMDR.fiscalOutState;
    // adiciona DRs selecionadas
    this.selected.forEach((el: DR) => {
      mdr.drList.push(el);
    });

    let flag = false;
    if (mdr.type === 'Cross Doc') {
      this.createConsolidationMDR();
      return;
    } else if (mdr.type === 'Cross Docking' && typeMDR.data) {
      this.createNewConsolidationMDR(mdr);
      return;
    } else if (mdr.type === 'CMS' && typeMDR.data) {
      mdr.SAD = typeMDR.data;
      if (typeMDR.data === true) {
        flag = true;
      }
    } else if (mdr.type === 'DTR' && typeMDR.data) {
      mdr['dtr'] = typeMDR.data;
      mdr['drInfo'] = {
        pickupDate: typeMDR.pickupDate,
        pickupPhone: typeMDR.pickupPhone,
        pickupSite: typeMDR.pickupSite,
      };
    } else if (mdr.type === 'MF') {
      mdr.status = 'Nova MDR';
    }
    // }else if(mdr.type === 'DTR' && typeMDR.data){
    //   const drList = [];
    //   for(let i = typeMDR.data.length-1; i>=0; i--){
    //     const dr = new DR();
    //     dr.deliveryAddress = typeMDR.data[i].address;
    //     dr.lat = typeMDR.data[i].lat;
    //     dr.lng = typeMDR.data[i].lng;
    //     dr.uf = typeMDR.data[i].uf;
    //     earlydate.setDate(earlydate.getDate() - i - 1);
    //     dr.dateScheduling = earlydate;
    //     drList.push(dr);
    //   }
    //   //console.log(drList);
    // }
    this.subscriptions.push(this.mdrService.insert(mdr, flag).subscribe( (data) => {
      this.drService.getAllOpen().subscribe(
        (data2) => {
          this.data = data2;
          this.snackBar.open('MDR EDB-TP-000' + data.id + ' criada com sucesso', 'Ok', {
            duration: 4000,
          });
          this.router.navigate(['logistic-order', data.id]);
        }
      );
    }));
  }

  onSelection(selected) {
    this.selected = selected;
  }
}

@Component({
  selector: 'app-type-mdr-dialog',
  templateUrl: 'type-mdr-dialog.html',
  styleUrls: ['./delivery-optimizer.component.scss']
})
export class TypeMdrDialogComponent implements OnInit, OnDestroy {
  columns = [
    {value: 'city', name: 'DELIVERY_ORDER/table/observation'},
    {value: 'uf', name: 'status'},
    {value: 'site', name: 'DELIVERY_ORDER/table/client'},
    {value: 'idDRidentifier', name: 'DELIVERY_ORDER/table/shippingCompany'},
    {value: 'hubSelected', name: 'DELIVERY_ORDER/table/hub'},
  ];
  public types = [{id: 'Cross Docking', name: 'Cross Docking'}, {id: 'MF', name: 'MF'}, {id: 'EDB', name: 'EDB'}, {id: 'ED', name: 'ED'}, {id: 'CMS', name: 'CMS'}, {id: 'DTR', name: 'DTR'}, {id: 'Cross Doc', name: 'Cross Doc'}];
  typeMDR: any;
  returnLeg = false;
  listAddresses = [];
  addressReturn = [];
  pickupSite = null;
  pickupDate = null;
  cellphone = '';
  address = null;
  hub = null;
  hubList = [];
  drs = [];
  valid = true;

  // fiscal in/out state
  icmsState = {
    in: {
      current: null,
      all: [],
    },
    out: {
      current: null,
      all: [],
    }
  };
  selectedDrs = [];

  @ViewChild('search')
  public searchElementRef: ElementRef;
  @ViewChild('pickupSiteSearch')
  public pickupSiteElementRef: ElementRef;

  subscriptions: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<TypeMdrDialogComponent>,
    private mapsAPILoader: MapsAPILoader,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public hubService: HubService,
    public snackBar: MatSnackBar,
    private priceMaker: PriceMakerService,
  ) {
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  goTo(row, that) {
    console.log(row);
    // that.router.navigate(['logistic-order', row.id]);
  }

  addList(listPayments) {
    this.selectedDrs = listPayments;
  }

  addHub() {
    this.selectedDrs.forEach(el => {
      this.drs.forEach(k => {
        if (k === el) {
          el['hubSelected'] = this.hub;
          k['hubSelected'] = this.hub;
        }
      });
    });
    console.log(this.drs);
  }

  ngOnInit() {
    this.typeMDR = null;
    console.log(this.data.selected);
    this.drs = this.data.selected;

    // pega in/out states
    this.subscriptions.push(this.priceMaker.getIcmsStates().subscribe((response) => {
      this.icmsState.in.all = response.in;
      this.icmsState.out.all = response.out;
    }));

    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address']
      });
      const autocompletepickup = new google.maps.places.Autocomplete(this.pickupSiteElementRef.nativeElement, {
        types: ['address']
      });
      autocompletepickup.addListener('place_changed', () => {
        const place = autocompletepickup.getPlace();
        this.pickupSite = place.formatted_address;
      });
    });

    this.subscriptions.push(this.hubService.getAll(this.valid).subscribe((resp) => {
      this.hubList = resp;
    }));
  }

  addAddress() {
    const address = ((document.getElementById('deliveryAddress') as HTMLInputElement).value);
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode( {'address': address}, (results, status) => {
      // console.log(results);
      const index = this.listAddresses.length - 1;
      if (status === google.maps.GeocoderStatus.OK) {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        const uf = results[0].address_components[2].short_name;
        this.addressReturn.push({address: address, lat: lat, lng: lng, uf: uf});
      } else {
        this.addressReturn.splice(index, 1);
        // this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', {duration: 2000});
        // console.log(status);
      }
    });
  }

  removeAddress(i) {
    this.addressReturn.splice(i, 1);
  }

  onYesClick(): void {
    if (this.typeMDR === 'CMS') {
      this.dialogRef.close({
        type : this.typeMDR,
        data: this.returnLeg,
        fiscalInState: this.icmsState.in.current,
        fiscalOutState: this.icmsState.out.current,
      });
    } else if (this.typeMDR === 'DTR') {
      this.dialogRef.close({
        type : this.typeMDR,
        data: this.addressReturn,
        fiscalInState: this.icmsState.in.current,
        fiscalOutState: this.icmsState.out.current,
        pickupDate: this.pickupDate,
        pickupSite: this.pickupSite,
        pickupPhone: this.cellphone
      });
    } else if (this.typeMDR === 'Cross Docking') {
      // for (let dr of this.drs){
      //   if(dr.hubSelected === null){
      //     this.snackBar.open("Falta selecionar HUBs para entregas", 'OK', {duration: 4000});
      //     return;
      //   }
      // }
      this.dialogRef.close({
        type : this.typeMDR,
        data: this.hub,
        fiscalInState: this.icmsState.in.current,
        fiscalOutState: this.icmsState.out.current,
      });
    } else {
      this.dialogRef.close({
        type: this.typeMDR,
        fiscalInState: this.icmsState.in.current,
        fiscalOutState: this.icmsState.out.current,
      });
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
