import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import {UOCMDR} from '../models/uoc__mdr';
@Injectable()
export class UOCObservable {
    private uoc = new  Subject<UOCMDR[]>();
    currentUOC = this.uoc.asObservable();

    changeUOC(getUOC: UOCMDR[]) {
      this.uoc.next(getUOC);
      // console.log(this.uoc);

    }


}
