import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
var base_url = environment.apiUrl;
export var ACESSO;
(function (ACESSO) {
    ACESSO["LIBERADO"] = "liberado";
    ACESSO["SEM_ACESSO"] = "sem acesso";
    ACESSO["BLOQUEADO"] = "bloqueado";
    ACESSO["SEM_CHAVE"] = "sem chave";
})(ACESSO || (ACESSO = {})); // de backend/app/entity/Site.ts
export var INFRA;
(function (INFRA) {
    INFRA["OK"] = "ok";
    INFRA["NOK"] = "nok";
})(INFRA || (INFRA = {})); // de backend/app/entity/Site.ts
export var ESTRUTURA;
(function (ESTRUTURA) {
    ESTRUTURA["EX"] = "existente";
    ESTRUTURA["NE"] = "nao existente";
})(ESTRUTURA || (ESTRUTURA = {})); // de backend/app/entity/Site.ts
export var TIPO;
(function (TIPO) {
    TIPO["ROOF_TOP"] = "roof top";
    TIPO["GREEN_FIELD"] = "green field";
    TIPO["ED_RESIDENCIAL"] = "ed. residencial";
    TIPO["ED_COMERCIAL"] = "ed. comercial";
    TIPO["BTS_OUTDOOR"] = "bts outdoor";
    TIPO["BTS_INDOOR"] = "bts indoor";
    TIPO["CONTEINER"] = "conteiner";
    TIPO["SENCILIO"] = "sencilio";
})(TIPO || (TIPO = {})); // de backend/app/entity/SiteTipo.ts
var SiteService = /** @class */ (function () {
    function SiteService(http) {
        this.http = http;
        this.uid = 'SiteService-';
        // set token if saved in local storage
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
    }
    SiteService.prototype.getEnums = function () {
        var enums = {
            acesso: [],
            infra: [],
            estrutura: [],
            tipo: []
        };
        var prefix = 'SITE_INVESTIGATION/enum/';
        enums.acesso = Object.keys(ACESSO).map(function (el) { return ({ value: ACESSO[el], show: prefix + 'ACESSO/' + el }); });
        enums.infra = Object.keys(INFRA).map(function (el) { return ({ value: INFRA[el], show: prefix + 'INFRA/' + el }); });
        enums.estrutura = Object.keys(ESTRUTURA).map(function (el) { return ({ value: ESTRUTURA[el], show: prefix + 'ESTRUTURA/' + el }); });
        enums.tipo = Object.keys(TIPO).map(function (el) { return ({ value: TIPO[el], show: prefix + 'TIPO/' + el }); });
        return enums;
    };
    SiteService.prototype.read = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.get(base_url + 'site', { headers: header }).map(function (response) {
            return response;
        });
    };
    SiteService.prototype.readById = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.get(base_url + 'site/' + id, { headers: header }).map(function (response) {
            return response;
        });
    };
    SiteService.prototype.delete = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        var options = {
            headers: header,
            body: { id: id }
        };
        return this.http.delete(base_url + 'site', options).map(function (response) {
            return response;
        });
    };
    SiteService.prototype.update = function (site) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.put(base_url + 'site', site, { headers: header }).map(function (response) {
            return response;
        });
    };
    SiteService.prototype.create = function (site) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.post(base_url + 'site', site, { headers: header }).map(function (response) {
            return response;
        });
    };
    SiteService.prototype.getShippingCompanys = function () {
        var _this = this;
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        if ((this.uid + 'getShippingCompanys') in localStorage) {
            return new Observable(function (observer) {
                observer.next(JSON.parse(localStorage.getItem(_this.uid + 'getShippingCompanys')));
            });
        }
        else {
            return this.http.get(base_url + 'site/token/shippingcompanys', { headers: header }).map(function (response) {
                localStorage.setItem(_this.uid + 'getShippingCompanys', JSON.stringify(response));
                return response;
            });
        }
    };
    SiteService.prototype.getShippingCompanyUsers = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.get(base_url + 'site/token/shippingcompanys/users', { headers: header }).map(function (response) {
            return response;
        });
    };
    SiteService.prototype.sendLSPEmail = function (siteId, users) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.post(base_url + 'site/token/email', { siteId: siteId, users: users }, { headers: header }).map(function (response) {
            return response;
        });
    };
    SiteService.prototype.createTokenAware = function (opts) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        var formData = new FormData();
        if ('file' in opts && opts.file) {
            formData.append('file', opts.file, opts.file.name);
        }
        var param = new HttpParams().set('id', opts.id)
            .append('lspid', opts.lspid)
            .append('visitadatahora', opts.visitadatahora.toISOString());
        return this.http.post(base_url + 'site/tokenAware', formData, {
            headers: header,
            params: param,
            reportProgress: true
        }).map(function (response) {
            return response;
        });
    };
    SiteService.prototype.createToken = function (opts) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        var formData = new FormData();
        if ('file' in opts && opts.file) {
            formData.append('file', opts.file, opts.file.name);
        }
        var param = new HttpParams().set('id', opts.id)
            .append('lspid', opts.lspid)
            .append('visitadatahora', opts.visitadatahora.toISOString());
        return this.http.post(base_url + 'site/token', formData, {
            headers: header,
            params: param,
            reportProgress: true
        }).map(function (response) {
            return response;
        });
    };
    SiteService.prototype.readWithToken = function (siteid, accesstoken) {
        var query = '?accesstoken=' + accesstoken + '&siteid=' + siteid;
        return this.http.get(base_url + 'site/token/' + siteid + query).map(function (response) {
            return response;
        });
    };
    SiteService.prototype.updateFiles = function (siteid, files) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        var formData = new FormData();
        files.forEach(function (el) {
            formData.append('file', el.file, el.field);
        });
        var param = new HttpParams().set('siteid', String(siteid));
        return this.http.post(base_url + 'site/files', formData, {
            headers: header,
            params: param,
            reportProgress: true
        }).map(function (response) {
            return response;
        });
    };
    SiteService.prototype.updateFilesWithToken = function (siteid, accesstoken, files) {
        var formData = new FormData();
        files.forEach(function (el) {
            formData.append('file', el.file, el.field);
        });
        var param = new HttpParams().set('siteid', String(siteid)).append('accesstoken', accesstoken);
        return this.http.post(base_url + 'site/token/files', formData, {
            params: param,
            reportProgress: true
        }).map(function (response) {
            return response;
        });
    };
    SiteService.prototype.updateWithToken = function (siteid, accesstoken, site) {
        var query = '?accesstoken=' + accesstoken + '&siteid=' + siteid;
        return this.http.put(base_url + 'site/token' + query, site).map(function (response) {
            return response;
        });
    };
    SiteService.prototype.approve = function (siteid, approveWithoutCost) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.put(base_url + 'site/approve', { id: siteid, withoutCost: approveWithoutCost }, { headers: header }).map(function (response) {
            return response;
        });
    };
    SiteService.prototype.getSiteID = function (idZVSD1) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.get(base_url + 'site/fromZVSD1/' + idZVSD1, { headers: header });
    };
    SiteService.prototype.getSiteFromCode = function (code) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.get(base_url + 'site/fromCODE/' + code, { headers: header });
    };
    SiteService.prototype.getPDF = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.get(base_url + 'site/pdf/' + id, {
            headers: header,
            responseType: 'blob'
        });
    };
    return SiteService;
}());
export { SiteService };
