<div class="d-flex justify-content-center align-items-center">

  <!-- sem DR em transito -->
  <p *ngIf="!dr">{{'WITHOUT_LOGIN/no-dr' | translate}}</p>

  <!-- card da ultima DR em transito -->
  <mat-card *ngIf="dr" class="p-0 py-1" [ngClass]="getStatusClass()">
    <mat-card-title class="m-0 pl-2 d-flex justify-content-between align-items-center">
      <div>{{ dr.mdr ? (pickupID ? pickupID : (dr.idMDR | mdrId)) : 'sem MDR associada' }}</div>
      <div class="m-0 p-0 align-items-center">
        <i text class="fa"
          [matTooltip]="tooltip.join(', ')"
          matTooltipPosition="left"
          [ngClass]="icons[color]"
          *ngIf="icons[color]">
        </i>
      </div>
    </mat-card-title>
    <mat-card-content class="px-2 py-1">
      <div class="d-flex justify-content-center smaller-text">
        <div>{{dr.dateScheduling | date: 'dd/MM/yyyy - HH:mm' }}</div>
      </div>
      <div class="d-flex justify-content-center smaller-text">
        <div>{{ dr.mdr ? dr.mdr.shippingCompany : 'sem transportadora associada' }}</div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="d-flex justify-content-center align-items-center">
          <div>{{ dr.client ? dr.client : 'sem cliente' }}</div>
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <div>{{ dr.site ? dr.site : 'sem site' }}</div>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="p-0 col-md-5 d-flex justify-content-center align-items-center">
          <div>{{( dr.pickupAddress ? dr.pickupAddress : 'sem endereço' ) | address : '%c/%E' }}</div>
        </div>
        <i text class="fa fa-arrow-right"></i>
        <div class="p-0 col-md-5 d-flex justify-content-center align-items-center">
          <div>{{( dr.deliveryAddress ? dr.deliveryAddress : 'sem endereço' ) | address : '%c/%E' }}</div>
        </div>
      </div>

    </mat-card-content>
  </mat-card>

</div>
