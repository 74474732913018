import { Injectable } from '@angular/core';
import { Router, CanActivate, CanDeactivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import decode from 'jwt-decode';
import { Observable } from 'rxjs/Observable';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class RoleGuardService implements CanActivate, CanDeactivate<CanComponentDeactivate> {

  constructor(public auth: AuthService, public router: Router) {}

  /*****
   * chama a função .canDeactivate() do componente usando o canDeactivate guard
   */
  canDeactivate(component: CanComponentDeactivate) {
    return component.canDeactivate ? component.canDeactivate() : true;
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    // se ja esta autenticado
    if (this.auth.isAuthenticated()) { return true; }
    this.router.navigate(['login']);
    return false;
  }

}
