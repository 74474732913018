import {DR} from './dr';

export class UOCMDR {
  id: number;
  uoc: string;
  idMDR: number;
  value: number;
  observation: string;
  isCopq: string;
  drList: DR[];
  needsAttachment: boolean;
  idOccurrence: number;

  constructor() {
    this.id = null;
    this.uoc = '';
    this.idMDR = null;
    this.value = null;
    this.observation = '';
    this.isCopq = null;
    this.drList = [];
    this.needsAttachment = false;
    this.idOccurrence = null;
  }
}
